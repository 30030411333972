export const theme = {
	colors: {
		mainPurple: '#7D5EFF',
		mainPurpleShadow: 'rgba(13,0,68,0.18)',
		mainPurpleShadowForWhite: 'rgba(13,0,68,0.15)',
		mainNavyBlue: '#522DED',
		mainNavyBlueShadow: 'rgba(82,45,237,.8)',
		mainLightBlue: '#ABF3E5',
		mainWhite: '#FFFFFF',
		mainShadow: '#0D0044',
		mainDarkPurple: '#372B74',
		mainMediumPurple: '#524279',
		mainTextLightBlue: '#ABF3E5',
		mainButtonLightBlue: '#ABF3E5',
		mainTextWhite: '#FFFFFF',
		mainTextNavyBlue: '#522DED',
		mainTextDarkGrey: '#2E302C',
		mainClientsBorder: '#F3F3F3',
		mainTextFooterNavy: '#4124BD',
		mainTextDarkBlue: '#261856',
		mainTextLabelPurple: '#6F4DFC',
		componentsPulseDots: '#00EEC1',
		componentsBlockGrey: '#F5F5F5',
		componentsBlockGreen: '#ABF3E5',
		formInputBg: 'rgb(92, 65, 237)',
		componentsBlockNavy: '#522DED',
		componentsButtonNavy: '#6F4DFB',
		componentsBlockTextNavy: '#694CFC',
		contactUsImageBlend: 'rgba(83,45,237,0.19)',
		tilesGreen: '#ABF3E5',
		sliderDot: '#522DED',
		sliderDotActive: '#241757',
		textRed: '#ff0000',
		navyBlueOpacity08: 'rgba(82,45,237,0.8)',
		navyBlueOpacity00: 'rgba(82,45,237,0)',
		tableBorderGrey: 'rgba(220, 220, 220, 1)',
	},
	fonts: {
		headerFont: "'Signika Negative', sans-serif",
		textFont: "'Lato', sans-serif",
		weight: {
			light: '300',
			normal: '400',
			semiBold:'600',
			bold: '700'
		}
	}
};

