import React, { Component } from "react"
import styled from "styled-components"
import BurgerSVG from "../../../icons/brand/burger.svg"
import { Link } from "gatsby"
import ArrowSVG from "../../../icons/blue_arrow.svg"
import pose from "react-pose"
import { urlToOtherLang, urlToPage } from "../../common/type-to-url-match/type-to-url-match"
import { connect } from "react-redux"
import { toggleSubmenu, toggleLanguage } from "../../../state/app"
import { LocalizationContext } from "../../../layout/layout"

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  position: relative;
`

const PosedUlWrapper = pose.ul({
  hidden: {
    x: 300,
    opacity: 0,
    zIndex: 0,
    transition: {
      opacity: { ease: "linear", duration: 300 },
      default: { ease: "linear", duration: 500 },
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    width: "auto",
    transition: {
      opacity: { ease: "linear", duration: 300, delay: 200 },
      default: { ease: "linear", duration: 500 },
    },
  },
})

const StyledUl = styled(PosedUlWrapper)`
	position: relative;
    flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: none;
	@media( min-width: 992px ) {
	  display: flex;
	  white-space: nowrap;
	}
`

const StyledWrapper = styled.div`
display: none;
	@media( min-width: 992px ) {
	display: flex;
	}
`

const StyledLi = styled.li`
  margin-right: 25px;
  display: flex;
  align-items: center;
`

const StyledNavLink = styled(Link)`
	color: white;
	font-weight: 600;
	text-decoration: none;
	font-size: 18px;
	line-height: 18px;
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.mainPurple};
	border: none;
	box-shadow: none;
	outline: none;
	cursor: pointer;
	white-space: nowrap;
  &.is-sticky {
    font-size: 14px;
  }
  &.inverted {
    color: ${({ theme }) => theme.colors.mainTextLabelPurple};
    background-color: ${({ theme }) => theme.colors.mainWhite};
  }
  &.submenuBtn {
  margin-right: -25px;
  padding-right: 25px;
  z-index: 1;
  }
  span {
    padding-top: 3px;
  }
`

const ArrowWrapper = pose.div({
  normal: { transform: "rotate(0deg)" },
  rotated: { transform: "rotate(180deg)" },
})

const StyledArrowWrapper = styled(ArrowWrapper)`
  margin-right: 12px;
  display: flex;
  align-items: center;
  transform: rotate(180deg);
`

const StyledGetStartedNavLink = styled(StyledNavLink)`
  border-color: ${({ theme }) => theme.colors.mainTextLightBlue};
  border-width: 2px;
  border-style: solid;
  border-radius: 23px;
  padding: 9.5px 32px;
  z-index: 1;
  &.is-sticky {
    padding: 7.5px 32px;
  }
  &.inverted {
    color: ${({ theme }) => theme.colors.mainTextLabelPurple};
    background-color: ${({ theme }) => theme.colors.mainWhite};
  }
`

const StyledSubnavSubLink = styled(StyledNavLink)`
  color: ${({ theme }) => theme.colors.mainWhite};
  &.active {
    color: ${({ theme }) => theme.colors.mainTextLightBlue};  
  }
`

const StyledBurgerWrapper = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
`

const StyledBurgerSVG = styled(BurgerSVG)`
  &.is-sticky {
    height: 37px;
  }
`

const StyledLangSwitchButton = styled(Link)`
  text-decoration: none;
  padding: 0 0 0 7px;
  font-size: 18px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  color: ${({ theme }) => theme.colors.mainWhite};
  display: block;
  -webkit-tap-highlight-color: transparent;
  &.active {
    display: none;
  }
  &.inverted {
    color: ${({ theme }) => theme.colors.mainTextLabelPurple}

  }
`
const StyledUlWithLanguages = styled.ul`
  position: absolute;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	right: -25px;
	padding: 0;
	display: none;
	@media( min-width: 992px ) {
	  display: ${({ isListVisible }) => isListVisible === true ? "flex" : "none"};
	  white-space: nowrap;
	}
`
const StyledCurrentLanguage = styled(Link)`
  text-decoration: none;
  display: none;
  position: relative;
  font-size: 18px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  color: ${({ theme }) => theme.colors.mainTextLightBlue};
  padding-left: 5px;
  @media(min-width: 992px){
    display: block;
    margin-left: 10px;
  }

`

class Nav extends Component {
  submenuElements = []

  componentDidMount() {
    this.checkIfSubmenuActive()
    this.checkIfLanguageSelected()
  }

  checkIfSubmenuActive() {
    let submenuActive = false
    this.submenuElements.forEach((element) => {
      if (element.classList && element.classList.contains("active")) {
        submenuActive = true
      }
    })
    if (submenuActive) {
      this.setState({ isSubmenuVisible: true })
    }
  }

  checkIfLanguageSelected() {
    let languageSelected = true

    if (languageSelected) {
      this.setState({ isLanguageSwitcherVisible: true })
    }
  }

  render() {
    const getItemType = (item) => {
      return (item.archeePrzyciskHomeLink[0] && item.archeePrzyciskHomeLink[0].__typename) || ""
    }
    const { isSticky, toggleMenu, inverted, submenuData, menuBtn, isSubmenuVisible, dispatch, localize, isLanguageSwitcherVisible } = this.props
    const { slug, lang } = localize
    const btnTitle = menuBtn.archeePrzyciskHomeTekst || ""
    const submenuTitle = (submenuData[0] && submenuData[0].title) || ""
    const submenuItemsArray = submenuData.slice(1) || []
    const submenuItems = submenuItemsArray.map((item) => {
      const type = (item.archeeWybierzPodstrone[0] && item.archeeWybierzPodstrone[0].__typename) || ""
      return {
        title: (item.archeeWybierzPodstrone[0] && item.archeeWybierzPodstrone[0].title) || "",
        type: type,
        url: urlToPage(lang)[type],
      }
    })

    const classes = []
    if (isSticky) {
      classes.push("is-sticky")
    }
    if (inverted) {
      classes.push("inverted")
    }
    const activeLanguage = (lang === "pl") ? "pl" : "en"
    return (
      <StyledNav>
        <StyledWrapper>
          <StyledLi>
            <StyledUl pose={isSubmenuVisible ? "visible" : "hidden"} >
              {submenuItems.map((item, key) =>
                <StyledLi key={key}>
                  <StyledSubnavSubLink
                    activeClassName="active"
                    className={classes.join(" ")}
                    to={item.url}
                    ref={submenuElement => this.submenuElements.push(submenuElement)}>{item.title}</StyledSubnavSubLink>
                </StyledLi>)
              }
            </StyledUl>
            <StyledNavLink as="button" onClick={() => dispatch(toggleSubmenu(!isSubmenuVisible))}
                           className={classes.join(" ") + " submenuBtn"} inverted={inverted}>
              <StyledArrowWrapper pose={isSubmenuVisible ? "normal" : "rotated"}>
                <ArrowSVG/>
              </StyledArrowWrapper>
              <span>{submenuTitle}</span>
            </StyledNavLink>
          </StyledLi>
          <StyledLi>
            <StyledGetStartedNavLink activeClassName="active" to={urlToPage(lang)[getItemType(menuBtn)]}
                                     className={classes.join(" ")}>{btnTitle}</StyledGetStartedNavLink>
          </StyledLi>
        </StyledWrapper>
        <StyledBurgerWrapper onClick={toggleMenu}>
          <StyledBurgerSVG className={classes.join(" ")}/>
        </StyledBurgerWrapper>
        <StyledCurrentLanguage as={"button"}
                               onClick={() => dispatch(toggleLanguage(!isLanguageSwitcherVisible))}>{activeLanguage}</StyledCurrentLanguage>
        <StyledUlWithLanguages isListVisible={isLanguageSwitcherVisible}>
          <StyledLangSwitchButton to={urlToOtherLang(slug, lang, "en")}
                                  onClick={() => dispatch(toggleLanguage(!isLanguageSwitcherVisible))}
                                  className={lang === "en" ? "active" : classes.join(" ")}
                                  inverted={inverted}>en</StyledLangSwitchButton>
          <StyledLangSwitchButton to={urlToOtherLang(slug, lang, "pl")}
                                  onClick={() => dispatch(toggleLanguage(!isLanguageSwitcherVisible))}
                                  className={lang === "pl" ? "active" : classes.join(" ")}
                                  inverted={inverted}>pl</StyledLangSwitchButton>
        </StyledUlWithLanguages>
      </StyledNav>
    )
  }
}

const ConnectedNav = connect(state => ({
  isSubmenuVisible: state.app.isSubmenuVisible,
  isLanguageSwitcherVisible: state.app.isLanguageSwitcherVisible,
}), null)(Nav)

export default React.forwardRef((props, ref) => (
  <LocalizationContext.Consumer>
    {localize => <ConnectedNav {...props} localize={localize} ref={ref}/>}
  </LocalizationContext.Consumer>
))
