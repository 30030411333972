import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import styled from "styled-components"
import getSingleImage from "../../../utils/getSingleImage"
import { LocalizationContext } from "../../../layout/layout"
import { useSelector } from 'react-redux';

const StyledLogoSVG = styled.img`
  display: none;
  @media( min-width: 768px ) {
    display: block;
    width: 225px;
    height: 100%;
  }
  &.show {
    display: none;
  }
`

const StyledSygnetSVG = styled.img`
  display: block;
  width: 45px;
  @media( min-width: 768px ) {
    display: none;
  }
  &.show {
    display: block;
  }
  &.big-logo{
    width: 77px;
  }
`

const Logo = ({ isSticky, inverted, logoData, langKey, localize }) => {

  const { lang } = localize;
  const globalDependsOnLang = lang === 'pl' ? logoData.logo.nodes : logoData.logoEn.nodes

  const archeeLogo = globalDependsOnLang?.[0]?.archeeLogo || ""
  const archeeLogoInverted = globalDependsOnLang?.[0]?.archeeLogoOdwrocone || ""
  const archeeSygnet = globalDependsOnLang?.[0]?.archeeLogoSygnet || ""
  const archeeSygnetInverted = globalDependsOnLang?.[0]?.archeeLogoSygnetOdwrocone || ""

  const isSubmenuVisible = useSelector((state) => state.app.isSubmenuVisible)

  const classes = []
  if (isSticky) {
    classes.push("show")
  }
  else{
    if (isSubmenuVisible) {
      classes.push("show")
      classes.push("big-logo")
    }
  }

  const to = langKey === "pl" ? "/" : "/" + langKey

  return (
    <Link to={to}>
      <StyledLogoSVG src={inverted ? getSingleImage(archeeLogoInverted) : getSingleImage(archeeLogo)}
                     className={classes.join(" ")}/>
      <StyledSygnetSVG src={inverted ? getSingleImage(archeeSygnetInverted) : getSingleImage(archeeSygnet)}
                       className={classes.join(" ")}/>
    </Link>
  )
}

const LocalizedLogo = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query{
      logo: allCraftArcheeLogoGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeLogo {
            url
          }
          archeeLogoOdwrocone {
            url
          }
          archeeLogoSygnet {
            url
          }
          archeeLogoSygnetOdwrocone {
            url
          }
        }
      }
      logoEn: allCraftArcheeLogoGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeLogo {
            url
          }
          archeeLogoOdwrocone {
            url
          }
          archeeLogoSygnet {
            url
          }
          archeeLogoSygnetOdwrocone {
            url
          }
        }
      }
    }
  `);
  return (
      <LocalizationContext.Consumer>
        {localize => <Logo {...props} localize={localize} logoData={data} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default LocalizedLogo
