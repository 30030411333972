import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import PageContainer from "../../pageContainer"
import MenuCloseSVG from "../../../icons/brand/close.svg"
import MenuItem from "./menuItem/menuItem"
import { urlToOtherLang, urlToPage } from "../../common/type-to-url-match/type-to-url-match"
import { LocalizationContext } from "../../../layout/layout"
import Logo from "../logo/logo"

const StyledHeaderWrapper = styled.div`
  height: 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -6px;
  margin-top: -16px;
  
  @media( min-width: 768px ) {
    margin-right: 72px;
    margin-top: -24px;
    height: 170px;  
  }
`

const LogoMenuWrapper = styled.div`
  display: none;
  
  @media( min-width: 768px ) {
    display: flex;
  }
`

const StyledMenuCloseWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 100;
  -webkit-tap-highlight-color: transparent;
  width: 46px;
  height: 46px;
  transform-origin: center;
   transition: 300ms ease-in-out;
  &:focus {
    outline: none !important;
  }
  &:hover {
    transform: rotate(90deg);
  }
`

const StyledFloatRightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc( 100vh - 170px );
  
  @media( min-width: 768px ) {
    justify-content: flex-end;
    margin-right: 72px;
    height: calc( 100vh - 180px );
  }
`

const StyledMenuUl = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-top: calc(50vh - 67px);
  margin-bottom: calc(50vh + 67px);
  padding: 0 0 0 50px;
  @media(min-width: 768px){
  padding: 0;
    margin-top: calc(50vh - 85px);
    margin-bottom: calc(50vh + 85px);
  }
`
const StyledLanguageSelectorWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  bottom: 40px;
  right: 50px;
  width: 60px;
  height: 30px;
  @media(min-width: 992px){
    display: none;
  }
`

const StyledLangSwitchButton = styled(Link)`
  text-decoration: none;
  width: 50%;
  font-size: 18px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  color: ${({ theme }) => theme.colors.mainWhite};
  -webkit-tap-highlight-color: transparent;

  &.active {
    color: ${({ theme }) => theme.colors.mainTextNavyBlue};
  }
`

class Menu extends Component {

  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.hideMenu()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  render() {
    const { toggleMenu, menuData, localize } = this.props
    const { slug, lang } = localize
    const getItemType = (menuItem) => {
      return (menuItem.archeeWybierzPodstrone && menuItem.archeeWybierzPodstrone[0] && menuItem.archeeWybierzPodstrone[0].__typename) || ""
    }

    const getUrl = (menuItem) => {
      const type = getItemType(menuItem)
      return urlToPage(localize.lang)[type]
    }

    const getMenuItems = (menuItems) => menuItems.map((menuItem) => {
      return {
        text: menuItem.title,
        to: getUrl(menuItem),
        submenu: getMenuItems(menuItem.remoteChildren ? menuItem.remoteChildren : []),
      }
    })


    const menuMainItems = menuData.filter((item) => {
        return item.remoteParent === null ? item : null
      },
    )

    const menuItems = getMenuItems(menuMainItems)
    
    return (
      <PageContainer>
        <StyledHeaderWrapper>
          <LogoMenuWrapper>
            <Logo isSticky={false} inverted={false} langKey={localize.lang}/>
          </LogoMenuWrapper>
          <Link to="/">
          </Link>
          <StyledMenuCloseWrapper>
            <MenuCloseSVG onClick={toggleMenu}/>
          </StyledMenuCloseWrapper>
        </StyledHeaderWrapper>
        <StyledFloatRightWrapper>
          <StyledMenuUl>
            {menuItems.map((menuItem, key) => <MenuItem toggleMenu={toggleMenu} {...menuItem} key={key}/>)}
            <StyledLanguageSelectorWrapper>
              <StyledLangSwitchButton to={urlToOtherLang(slug, lang, "en")}
                                      className={lang === "en" ? "active" : ""}>en</StyledLangSwitchButton>
              <StyledLangSwitchButton to={urlToOtherLang(slug, lang, "pl")}
                                      className={lang === "pl" ? "active" : ""}>pl</StyledLangSwitchButton>
            </StyledLanguageSelectorWrapper>
          </StyledMenuUl>
        </StyledFloatRightWrapper>
      </PageContainer>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <LocalizationContext.Consumer>
    {localize => <Menu {...props} localize={localize} ref={ref}/>}
  </LocalizationContext.Consumer>
))
